<template>
  <BaseDraggableModal
      :name="modal_name"
      max-height="80vh"
      width="70vw"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      :ref="modal_name">
    <v-card :loading="loading">
      <BaseModal>
        <v-toolbar class="draggable_selector" dark color="primary" dense flat slot="header">
          <v-btn icon @click.stop="reset">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ page.title }}</v-toolbar-title>
          <v-spacer/>
          <v-btn text small @click.stop="save">
            <v-icon>mdi-content-save</v-icon>
          </v-btn>
        </v-toolbar>
        <div slot="content">
          <v-divider/>
          <v-card-text>
            <v-row>
              <v-col>
                <v-text-field
                  :class="'ma-0 pt-'+$store.getters.lineheight"
                  :label="$store.getters.translate('name')"
                  v-model="record.name">
                  <template #label>{{ $store.getters.translate('name') }}<span class="red--text"><strong>*</strong></span></template>
                </v-text-field>
                <v-select
                    :class="'ma-0 pt-'+$store.getters.lineheight"
                  :label="$store.getters.translate('permissions')"
                  v-model="record.permissions"
                  :items="$lodash.sortBy(permissions)"
                  item-text="label"
                  item-value="name"
                  chips
                  deletable-chips
                  small-chips
                  multiple
                  clearable
                  append-outer-icon="mdi-form-select"
                  @click:append-outer="selectAll"/>
              </v-col>
            </v-row>
          </v-card-text>
        </div>
      </BaseModal>
    </v-card>
  </BaseDraggableModal>
</template>

<script>
import BaseModal from "../../components/commonComponents/BaseModal";
import BaseDraggableModal from "../../components/commonComponents/BaseDraggableModal";
import helpFunctions from "../../plugins/helpFunctions";

export default {
  components: { BaseModal, BaseDraggableModal },
  props: ["module_name", "modal_name"],
  data() {
    return {
      page: {
        title: this.$store.getters.translate("create_role"),
        name: this.module_name,
      },
      record: {
        name: null,
        permissions: [],
      },
      permissions: [],
      loading: false,
    };
  },
  created() {
    this.load();
  },
  methods: {
    ...helpFunctions.modal_functions,
    load() {
      this.$http
          .get(this.$store.getters.appUrl + "v2/permissions")
          .then((response) => {
            this.permissions = [];
            response.data.forEach((option) => {
              this.permissions.push({
                name: option.name,
                label: this.$store.getters.translate(option.name)
              });
            });
          })
          .catch((error) => {
            this.$toasted.error(error);
          });
    },
    save() {
      if(!this.loading) {
        let error = false;
        if (!this.record.name || this.record.name === '') {
          this.$toasted.error(this.$store.getters.translate("required_field") + ": " + this.$store.getters.translate('name'));
          error = true;
        }
        if (!error) {
          this.loading = true;
          this.$http
              .post(this.$store.getters.appUrl + "v2/roles", this.record)
              .then((response) => {
                this.loading = false;
                if (response.status == 200) {
                  this.$toasted.success(this.$store.getters.translate("successfully_saved"));
                  this.$emit("refresh", response.data);
                  this.reset();
                } else {
                  var errorMessage = response.data.message;
                  var errors = Object.keys(response.data.message);
                  this.$toasted.error([errorMessage[errors[0]]]);
                }
              })
              .catch((error) => {
                this.$toasted.error(error);
                this.loading = false;
              });
        }
      }
    },
    selectAll() {
      this.record.permissions = this.$lodash.map(this.permissions, "name");
    },
    reset() {
      this.record = {
        name: null,
        permissions: [],
      };
      this.closeModal(this.modal_name);
    },
  },
};
</script>
